import React, { useEffect, useState } from "react";
import {
  Select,
  TextInput,
  Button,
  Modal,
  Text,
  Divider,
  SegmentedControl,
  Table,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import flat from "flat";
import { DatePicker } from "@mantine/dates";
import { formatISO, parseISO } from "date-fns";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { apiWrapWithErrorWithData } from "../../../utilities/apiHelpers";
import {
  getAgreementTitleApi,
  getAgreementTypesApi,
  getRegisterUserApi,
  bulkAgreementUploadApi,
  createBulkAgreements,
} from "../../../utilities/apis/agreements";
import TemplatesList from "../../../components/TemplateManager/TemplatesList";
import { downloadCsvApi } from "../../../utilities/apis/templates";
import styles from "./AgreementBulkCreate.module.css";
import colors from "../../../utilities/design";
import AgreementBulkParty from "./AgreementBulkParty";
import AgreementBulkConfirm from "./AgreementBulkConfirm";
import AgreementBulkSteps from "./AgreementBulkSteps";
import { getPartiesList } from "../../../utilities/apis/parties";
import { handleChangeUpload } from "./utils";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../redux/selectors";
import {
  getBussinessUnit,
  getUserRolesUsingRoleId,
} from "../../../utilities/apis/serviceProvider";
import { camelCaseToTitle } from "../../../utilities/utilities";
// import { parse } from "json2csv";

const AgreementBulkCreate = () => {
  const currentUser = useSelector(selectUserData);
  const reminderLabels = {
    15: "15 days",
    30: "30 days",
    45: "45 days",
    60: "60 days",
  };
  const navigate = useNavigate();

  const [step, setStep] = useState({ lastStep: false });

  const [typeOfAgreementData, setTypeOfAgreementData] = useState([]);
  const [agreementTitleData, setAgreementTitleData] = useState([]);
  const [businessUnitsOptions, setBusinessUnitsOptions] = useState([]);
  const [requestingUserData, setRequestingUserData] = useState(null);
  const [file, setFile] = useState(null);
  const [intialConfigs, setInitialConfigs] = useState({
    titleOfAgreement: "",
    typeOfAgreement: "",
    dateOfContract: "",
    noOfMonths: "",
    noOfYears: "",
    reminderTime: 0,
    count: 2,
    businessUnit: 0,
    templateId: 0,
    viewTemplate: false,
    editorContent: null,
  });
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAgreementTypes = async () => {
      const resp = await apiWrapWithErrorWithData(getAgreementTypesApi());
      console.log("1: ", resp);
      setTypeOfAgreementData(resp.typesOfAgreement);
    };
    getAgreementTypes();
  }, []);
  useEffect(() => {
    const getAgreementTitle = async () => {
      const resp = await apiWrapWithErrorWithData(getAgreementTitleApi());
      console.log("2 : ", resp);
      setAgreementTitleData(resp.titlesOfAgreement);
    };
    getAgreementTitle();
  }, []);
  useEffect(() => {
    const getRegisterUser = async () => {
      const res = await apiWrapWithErrorWithData(getRegisterUserApi());
      setRequestingUserData({
        requestingUserName: res.requestingUser.name,
        requestingUserEmail: res.requestingUser.email,
        requestingUserPhone: res.requestingUser.phone,
        requestingUserDesignation: res.requestingUser.designation,
        requestingUserBusinessDepartment: res.requestingUser.businessDepartment,
      });
      console.log("3: ", requestingUserData);
    };
    getRegisterUser();
  }, []);

  useEffect(() => {
    const fetchBusinessUnits = async () => {
      try {
        const { data } = await getBussinessUnit();
        if (data) {
          setBusinessUnitsOptions(
            data.businessunitList.map((unit) => ({
              value: unit.id + "",
              label: unit.name,
            })) || []
          );
        } else {
          console.error("Invalid business unit response format:", data);
        }
      } catch (error) {
        console.error("Error fetching business units:", error);
      }
    };
    fetchBusinessUnits();
  }, []);

  const downloadCsvHandler = async () => {
    // if (!formValidation()) return;

    if (intialConfigs.templateId === 0) {
      showNotification({
        title: "Bulk Agreement Form",
        message: "Please Select the agreement template",
        color: "red",
      });
      return;
    }

    const res = await apiWrapWithErrorWithData(
      downloadCsvApi({ templateId: intialConfigs.templateId })
    );
    if (res.success) {
      const { data } = res;
      const fields =
        data.fieldName && data.fieldName.length
          ? data.fieldName?.map((ele) => `ph_${ele}`)
          : [];
      let csvContent = `data:text/csv;charset=utf-8,`;

      let columns = [
        "businessUnit",
        "templateId",
        "typeOfAgreement",
        "titleOfAgreement",
        "requestingUserName",
        "requestingUserEmail",
        "dateOfContract",
        "noOfYears",
        "noOfMonths",
        "reminderTimeInDays",
        "location",
        "firstPartyName",
        "firstPartyEmail",
        "firstPartyAddress",
        "firstPartySignatory",
        "firstPartyRepresentative",
        "secondPartyName",
        "secondPartyEmail",
        "secondPartyAddress",
        "secondPartySignatory",
        "secondPartyRepresentative",
      ];
      const columnsHead = columns.map((ele) => camelCaseToTitle(ele));
      csvContent += columnsHead.join(",") + ",";
      csvContent += fields.length ? fields?.join(",") + "\n" : "\n";
      const preFiledValues = {
        ...requestingUserData,
        typeOfAgreement: intialConfigs.typeOfAgreement,
        titleOfAgreement: intialConfigs.titleOfAgreement,
        dateOfContract: intialConfigs.dateOfContract,
        noOfMonths: intialConfigs.noOfMonths,
        noOfYears: intialConfigs.noOfYears,
        templateId: intialConfigs.templateId,
        businessUnit: intialConfigs.businessUnit,
        reminderTimeInDays: intialConfigs.reminderTime,
        agreementType: intialConfigs.agreementType,
        signatureType: intialConfigs.signatureType,
      };

      let preFiledContent = "";
      columns.forEach((ele) => {
        if (preFiledValues[ele]) {
          preFiledContent += `${preFiledValues[ele]},`;
        } else {
          preFiledContent += ",";
        }
      });

      for (let i = 0; i < intialConfigs.count; i++) {
        csvContent += `${preFiledContent}\n`;
      }

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${data.name}.csv`);
      document.body.appendChild(link);
      link.click();
    }
  };
  function validate() {
    if (flowJson["7"]?.length > 0) {
      return true;
    } else if (flowJson["7"].length === 0) {
      return false;
    }
  }
  const uploadHandler = async (e) => {
    setFile(e.target.files[0]);
    const [csvData, formData] = await handleChangeUpload(e);

    console.log("csvData: ", csvData);

    setFormData(csvData);

    console.log("form Data: ", formData);
    setStep({ lastStep: true });
  };

  const [flowJson, setFLowJson] = useState({
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    sendfordirectsign: false,
  });
  const loginAsId = Number(currentUser.loginAs.id);
  const [signauthData, setSignauthData] = useState([]);

  useEffect(() => {
    const getSignauth = async () => {
      const signauths = await getUserRolesUsingRoleId(7);
      setSignauthData(signauths.data.userList);
      console.log(signauthData);
    };
    getSignauth();
  }, []);

  useEffect(() => {
    if (loginAsId === 3) {
      setFLowJson({
        3: [
          {
            userId: currentUser.id,
            isComplete: true,
          },
        ],
        4: [],
        5: [],
        6: [],
        7: [],
        sendfordirectsign: true,
      });
    } else if (loginAsId === 4) {
      setFLowJson({
        3: [],
        4: [
          {
            userId: currentUser.id,
            isComplete: true,
          },
        ],
        5: [],
        6: [],
        7: [],
        sendfordirectsign: false,
      });
    }
  }, [loginAsId]);

  console.log("flowJson: ", flowJson);
  console.log("intial COnfig: ", intialConfigs);

  console.log("formDAta: ", formData);
  console.log("step: ", step);

  console.log("files: ", file);

  const back = () => {
    setStep({ lastStep: false });
  };
  const onSubmit = async () => {
    setLoading(true);
    if (validate()) {
      const formDataObject = new FormData();
      formDataObject.append("file", file);
      formDataObject.append("flowJson", JSON.stringify(flowJson));
      const resp = await apiWrapWithErrorWithData(
        createBulkAgreements({ formDataObject })
      );
      if (resp.success) {
        showNotification({
          title: "bulk agreement creation",
          color: "green",
          message: "bulk agreements created successfully",
        });
        navigate("/app/agreements_manager/agreements-list");
      } else {
        showNotification({
          title: "bulk agreement creation",
          color: "red",
          message: "bulk agreements creation failed",
        });
        setLoading(false);
      }
    } else {
      showNotification({
        title: "please select sign-auth",
        color: "red",
        message: "select sign-auth to complete flowjson",
      })
    }
  };
  return (
    <div>
      {!step.lastStep && (
        <div className="w-full flex flex-col my-4">
          <div className="grid grid-cols-2 my-4">
            <Select
              required
              searchable
              creatable
              className="max-w-lg"
              label="Type of agreement"
              placeholder="Select type of agreement"
              data={typeOfAgreementData}
              onChange={(val) => {
                setInitialConfigs({ ...intialConfigs, typeOfAgreement: val });
              }}
              value={intialConfigs.typeOfAgreement}
            />
            <Select
              required
              searchable
              creatable
              className="max-w-lg"
              label="Title of agreement"
              placeholder="Select title of agreement"
              data={agreementTitleData}
              value={intialConfigs.titleOfAgreement}
              onChange={(val) => {
                setInitialConfigs({ ...intialConfigs, titleOfAgreement: val });
              }}
            />
          </div>
          <div className="grid grid-cols-2 my-4">
            <DatePicker
              required
              className="max-w-lg"
              label="Date of contract"
              placeholder="Select date"
              onChange={(val) =>
                setInitialConfigs({
                  ...intialConfigs,
                  dateOfContract: formatISO(val),
                })
              }
              value={intialConfigs.dateOfContract}
            />
            <Select
              required
              placeholder="Reminder time"
              label="Renewal Reminder Time (in days)"
              className="max-w-lg"
              data={[
                { value: 15, label: reminderLabels["15"] },
                { value: 30, label: reminderLabels["30"] },
                { value: 45, label: reminderLabels["45"] },
                { value: 60, label: reminderLabels["60"] },
              ]}
              onChange={(val) =>
                setInitialConfigs({ ...intialConfigs, reminderTime: val })
              }
            />
          </div>
          <div
            className="flex my-4 justify-between"
            style={{ alignItems: "center" }}
          >
            <div>
              <Text>Contract term</Text>
              <div
                className="flex flex-row justify-between"
                style={{ width: "200px" }}
              >
                <Select
                  required
                  label="Duration"
                  placeholder="Select years"
                  data={[
                    ...Array.from({ length: 11 }, (_, i) => ({
                      value: `${i}`,
                      label: `${i} year${i === 1 ? "" : "s"}`,
                    })),
                    { value: "100", label: "100 years" },
                  ]}
                  onChange={(val) =>
                    setInitialConfigs({ ...intialConfigs, noOfYears: val })
                  }
                  style={{ width: "160px", marginRight: "20px" }}
                />
                <Select
                  label="   "
                  placeholder="Select months"
                  data={[
                    { value: "0", label: "0 month" },
                    ...Array.from({ length: 12 }, (_, i) => ({
                      value: `${i + 1}`,
                      label: `${i + 1} month${i === 0 || i === 1 ? "" : "s"}`,
                    })),
                  ]}
                  onChange={(val) =>
                    setInitialConfigs({ ...intialConfigs, noOfMonths: val })
                  }
                  style={{ width: "160px" }}
                />
              </div>
            </div>
            <TextInput
              required
              label="Agreements Count"
              placeholder="count"
              type="number"
              style={{ width: "200px", marginRight: "20px" }}
              onChange={(e) =>
                setInitialConfigs({ ...intialConfigs, count: e.target.value })
              }
              value={intialConfigs.count}
            />
            <Button
              style={{
                backgroundColor: "#46BDE1",
                marginRight: "100px",
              }}
              onClick={(e) =>
                setInitialConfigs({ ...intialConfigs, viewTemplate: true })
              }
            >
              Choose agreement template
            </Button>
          </div>
          <div className=" grid grid-cols-2  my-4">
            <Select
              required
              searchable
              className="max-w-lg"
              placeholder="Enter Business Unit"
              label="Business Unit"
              data={businessUnitsOptions}
              dropdownPosition="bottom"
              onChange={(val) =>
                setInitialConfigs({ ...intialConfigs, businessUnit: val })
              }
            />
            <Select
              required
              placeholder="select sign-auth"
              label="sign-auth"
              className="max-w-lg"
              data={signauthData?.map((user) => ({
                value: user.id,
                label: user.name,
              }))}
              value={flowJson["7"][0]?.userId}
              onChange={(val) => {
                setFLowJson({
                  ...flowJson,
                  7: [{ userId: val, isComplete: false }],
                });
              }}
            />
          </div>
          <div>
            <div
              className="flex flex-row justify-center"
              style={{ marginTop: "30px" }}
            >
              <Button onClick={() => downloadCsvHandler()}>Download CSV</Button>
            </div>
          </div>
          <Divider className="my-4" />
          <div className={styles["upload-wrapper"]}>
            <div class={styles["file-input-wrapper"]}>
              <Text>Upload CSV file</Text>
              <Button className="my-2" color="gray">
                <Text>Upload CSV</Text>
              </Button>

              <input
                type="file"
                name="file"
                onChange={(e) => uploadHandler(e)}
              />
            </div>
          </div>

          {formData !== null && (
            <Button onClick={() => setStep({ lastStep: true })}>Next</Button>
          )}
        </div>
      )}
      {formData !== null && step.lastStep && (
        <>
          <AgreementBulkConfirm
            bulkAgreementData={formData}
            onPrevious={back}
            onSubmit={onSubmit}
            loading={loading}
          />
        </>
      )}
      <Modal
        overflow="inside"
        opened={intialConfigs.viewTemplate}
        onClose={() => {
          setInitialConfigs({ ...intialConfigs, viewTemplate: false });
        }}
        size="calc(80vw)"
      >
        <TemplatesList
          showNewTemplateBtn={false}
          templateType="Agreements"
          useTemplateFunc={(template) => {
            console.log({
              template,
            });
            setInitialConfigs({
              ...intialConfigs,
              templateId: template.id,
              editorContent: template.quillDelta,
              viewTemplate: false,
            });
          }}
        />
      </Modal>
    </div>
  );
};

export default AgreementBulkCreate;
